import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth.store";
import sites from "./modules/sites.store";
import devices from "./modules/devices.store";
import rides from "./modules/rides.store";
import bookings from "./modules/bookings.store";
import members from "./modules/members.store";
import payments from "./modules/payments.store";
import codes from "./modules/codes.store";
import zones from "./modules/zones.store";
import admin from "./modules/admin";
import guest from "./modules/guest";

import unlockCodes from "./modules/unlockCodes";
import rfidCodes from "./modules/rfidCodes";
import ride from "./modules/ride";

import { setApiHeaders } from "@/axios";
import socket from "@/socket";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		guest,
		sites,
		devices,
		rides,
		bookings,
		members,
		unlockCodes,
		rfidCodes,
		ride,
		payments,
		admin,
		codes,
		zones,
	},
	state: {
		pageLoading: true,
		deviceSidebar: false,
	},
	mutations: {
		// Page Loading
		setPageLoading(state, payload) {
			state.pageLoading = payload;
		},

		// Device Sidebar
		setDeviceSidebar(state, payload) {
			state.deviceSidebar = payload;
		},
	},
	actions: {
		handlePostLoginRequests: async ({ commit, dispatch }) => {
			commit("setPageLoading", true);

			console.log("post login requests");

			try {
				if (!localStorage.user) return;

				if (localStorage.selectedSiteID) {
					setApiHeaders({ siteId: localStorage.selectedSiteID });

					const sites = await dispatch("getSites");

					const site = sites.find((site) => site._id === localStorage.selectedSiteID);

					if (!site) {
						await dispatch("logout");
					}
				} else {
					let sites = await dispatch("getSites");
					sites.sort((a, b) => a._id - b._id);

					const site = sites[0];
					localStorage.selectedSiteID = site._id;

					setApiHeaders({ siteId: site._id });
				}

				await dispatch("getSites");
				await dispatch("getSite");
				await dispatch("getDevices");

				socket.open();
			} catch (error) {
				await dispatch("logout");
				this.$toast.error("Something went wrong, please try again");
			} finally {
				commit("setPageLoading", false);
			}
		},
	},
});
